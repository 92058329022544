﻿.vacancy-overview {
	overflow: hidden;
	margin: 0 0 rem(-15px);

	&__content {
		@extend .side-line;
		@extend %side-line-primary;
		padding: 0 0 0 rem(50px);
		margin: 0 0 rem(92px);
	}
}

.vacancies {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;

	&__column {
		display: flex;
	}
}

.vacancy {
	display: block;
	text-decoration: none;
	font-size: rem(16px);
	line-height: rem(26px);
	color: #000;
	width: 100%;
	margin: 0 0 rem(40px);
	flex-shrink: 0;
	background: #fff;
	transition: background 0.2s ease-in-out;

	&:hover {
		background: darken(#fff, 10%);
	}

	&__header {
		position: relative;
		height: rem(70px);
		background: url(../../images/header-background.jpg) center no-repeat;
		background-size: 850px auto;

		&:after {
			position: absolute;
			content: '';
			width: 100%;
			height: rem(10px);
			bottom: 0;
			left: 0;
			background: $color-secondary;
		}
	}

	&__content {
		padding: rem(41px) rem(50px) rem(43px);
	}

	&__title {
		font-size: rem(20px);
		line-height: rem(30px);
		color: $color-primary;
		margin: 0 0 rem(57px);
		font-weight: normal;
	}

	&__working-hours {
		display: block;
		margin: rem(-57px) 0 rem(31px);
	}

	&__link {
		display: block;
		color: $color-primary;
	}
}

﻿$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 34px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $color-primary;
$hamburger-layer-border-radius: 0;
$hamburger-hover-opacity: 1;
$hamburger-hover-transition-duration: 0.3s;
$hamburger-hover-transition-timing-function: ease-in-out;

@import "~hamburgers/_sass/hamburgers/hamburgers";

.hamburger {
	display: block;
	z-index: 1001;
	position: fixed;
	width: rem(60px);
	height: rem(54px);
	padding: 0 rem(10px);
	margin: 0 0 0 rem(-90px);
	bottom: rem(30px);
	left: 50%;
	transform: translateX(-50%);
	cursor: pointer;
	background: #fff;
	color: $base-text-color;
	opacity: 1;
	transition: all 0.3s ease-in-out;

	@include bp-min(m) {
		display: none;
	}

	@include bp-min(xl) {

		.headroom--not-top & {
			top: rem(216px);
		}
	}

	.hamburger-box {
		position: static;
	}

	&:hover,
	&:focus,
	&:active {
		background: darken(#fff, 5%);
	}
}
﻿.languages {
	@extend .clearfix;
	@extend .pull;
	position: relative;
	padding: 0 rem(12px);
	margin: rem(47px) 0 rem(42px);
	list-style: none;

	li {
		@extend .pull;

		&:first-child a:before {
			display: none;
		}
	}

	a {
		position: relative;
		font-weight: bold;
		color: $color-border;
		text-decoration: none;
		padding: 0 rem(8px);

		&:before {
			position: absolute;
			content: '';
			width: 1px;
			margin: rem(-7px) 0 0 0;
			height: rem(14px);
			top: rem(50%);
			left: -1px;
			background: $color-border;
		}

		&.active {
			color: $base-text-color;
		}
	}

	&:before {
		position: absolute;
		content: '';
		width: 1px;
		margin: rem(-7px) 0 0 0;
		height: rem(14px);
		top: rem(50%);
		left: -1px;
		background: $color-border;
	}

	&:after {
		position: absolute;
		content: '';
		width: 1px;
		margin: rem(-7px) 0 0 0;
		height: rem(14px);
		top: rem(50%);
		right: -1px;
		background: $color-border;
	}
}

﻿.fileupload {

	input + label {
		display: none;
	}

	.js & {

		input {
			width: 0.1px !important;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}

		input + label {
			position: relative;
			display: inline-block;
			margin: 0 0 rem(10px);
			overflow: visible;

			&:after {
				position: absolute;
				content: attr(data-caption);
				top: 0;
				margin: 0 0 0 rem(20px);
				left: 100%;
				color: #fff;
				white-space: nowrap;
			}
		}
	}
}

﻿.contact-box {
	@extend .clearfix;
	position: fixed;
	z-index: 3;
	height: rem(54px);
	width: rem(240px);
	margin: 0 0 0 rem(-120px);
	bottom: rem(30px);
	left: 50%;

	@include bp-min(m) {
		height: auto;
		width: auto;
		margin: 0;
		top: rem(363px);
		right: 0;
		bottom: auto;
		left: auto;
		display: flex;
		flex-direction: column;
	}

	&__item {
		position: absolute;
		height: rem(54px);
		width: rem(60px);
		color: #fff;
		left: rem(60px);
		transition: right 0.3s ease-in-out;
		text-indent: -9999px;

		@include bp-min(m) {
			height: rem(60px);
			position: relative;
			float: right;
			width: 100%;
			right: -100%;
			left: auto;
			text-indent: 0;
			clear: both;
			padding: rem(14px) rem(20px) 0;
		}

		a {
			display: block;
			color: #fff;
			text-transform: none;
		}

		&.-telephone {
			background: $color-primary;
			margin-right: rem(37px);

			&:before {
				@include icon($icon-telephone);
				font-size: rem(24px);
				background: $color-primary;
				padding-top: rem(16px);
				margin-right: 0;
			}

			a:before {
				@include icon($icon-telephone);
			}
		}

		&.-email {
			background: $color-primary-dark;
			margin-right: rem(-97px);
			left: rem(120px);

			@include bp-min(m) {
				left: auto;
			}

			&:before {
				@include icon($icon-mail);
				background: $color-primary-dark;
				font-size: rem(29px);
				padding-top: rem(16px);
				margin-right: 0;
			}

			a:before {
				@include icon($icon-mail);
			}
		}

		&.-location {
			background: $color-secondary;
			left: rem(180px);

			@include bp-min(m) {
				left: auto;
			}

			&:before {
				@include icon($icon-pointer);
				background: $color-secondary;
				font-size: rem(26px);
				padding-top: rem(16px);
				margin-right: 0;
			}

			a:before {
				@include icon($icon-pointer);
			}
		}

		&:before {
			position: absolute;
			display: none;
			content: '';
			width: rem(60px);
			height: rem(60px);
			top: 0;
			left: rem(-60px);
			text-align: center;

			@include bp-min(m) {
				display: block;
			}
		}

		&:hover {

			@include bp-min(m) {
				right: 0;
			}
		}

		a {

			&:before {
				position: absolute;
				font-size: rem(24px);
				top: 50%;
				left: 50%;
				text-indent: 0;
				transform: translate(-50%, -50%);

				@include bp-min(m) {
					display: none;
				}
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}
}

﻿.registration-form {
	position: relative;
	background: $color-secondary url(../../images/form-background.jpg) center no-repeat;
	background-size: cover;

	&__content-container {
		display: block;
		padding: rem(19px) 0 rem(16px);

		.object-fit & {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: center;
		}
	}

	&__content {
		position: relative;
		margin: rem(20px) 0 rem(20px);

		@extend .side-line;
		@extend .side-line__padding;
	}

	&__title {
		font-weight: 300;
		color: #fff;
		margin: 0 0 rem(16px);
	}

	&__subtitle {
		font-size: rem(18px);
		line-height: rem(28px);
		font-weight: 300;
		color: #fff;
	}

	form {
		display: flex;
		flex-wrap: wrap;
		margin: rem(-10px);

		div {
			margin: rem(10px);
		}
	}

	input {
		margin: 0;
		min-width: rem(248px);
	}

	.umbraco-forms-form {
		padding: rem(20px) 0 rem(20px);
	}

	.umbraco-forms-submitmessage {
		color: #fff;
	}
}

﻿.info-list {
	@include definition-list(20, xxs);
	margin: 0 0 rem(24px);

	dt {
		display: none;

		@include bp-min(xxs) {
			display: block;
		}
	}

	dd {

		@include bp-min(xxs) {
			padding-left: rem(20px);
		}
	}

	&.-big {
		@include definition-list(146, xxs);
	}

	&__link {
		color: $color-primary;
		text-decoration: none;

		&.-bold {
			font-weight: bold;
		}

		&.-no-style {
			color: inherit;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

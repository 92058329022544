﻿.overview {
	overflow: hidden;
	display: flex;
	flex-wrap: wrap;
	margin-bottom: rem(-15px);

	&.-center {
		justify-content: center;
	}

	&__header {
		@extend .side-line;
		@extend %side-line-primary;
		@extend .side-line__padding;
		margin: 0 0 rem(58px);

		&.-flex {

			.object-fit & {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				align-items: center;
			}

			.overview__content {
				max-width: rem(872px);
			}
		}
	}

	&__column {
		display: flex;
	}
}

.overview-item {
	display: flex;
	display: block;
	width: 100%;
	font-size: rem(16px);
	line-height: rem(26px);
	margin: 0 0 rem(40px);
	text-decoration: none;
	color: $base-text-color;
	background: #fff;
	transition: background 0.2s ease-in-out;

	.content-block:nth-child(odd) & {
		background: $color-gray;
	}

	&.contact-person {
		background: #fff;
	}

	&__image-container {
		@include responsive-container-psudo(600, 400);
		background: rgba($color-primary, 0.5);

		&:after {
			position: absolute;
			z-index: 1;
			content: '';
			width: 100%;
			height: rem(10px);
			background: $color-secondary;
		}
	}

	&__content {
		padding: rem(42px) rem(20px) rem(43px);

		@include bp-min(xs) {
			padding: rem(42px) rem(30px) rem(43px);
		}

		@include bp-min(s) {
			padding: rem(42px) rem(40px) rem(43px);
		}

		@include bp-min(m) {
			padding: rem(42px) rem(50px) rem(43px);
		}

		&.-small {
			padding: rem(42px) rem(20px) rem(43px);

			@include bp-min(s) {
				padding: rem(42px) rem(30px) rem(43px);
			}
		}
	}

	&__date {
		display: block;
		margin: 0 0 rem(27px);
		color: #999;
	}

	&__title {
		margin: 0 0 rem(13px);
		font-size: rem(20px);
		line-height: rem(30px);
		color: $color-primary;
		font-weight: normal;
	}

	&__text {
		margin: rem(-27px) 0 rem(27px);
	}

	&__info {
		@include definition-list(20, s);

		dt {
			display: none;

			@include bp-min(s) {
				display: block;
			}
		}
	}

	&__link {
		color: $color-primary;
		text-decoration: none;

		&.-no-style {
			color: inherit;
		}

		&.-bold {
			font-weight: bold;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&.-has-hover {

		&:hover {
			background: darken(#fff, 5%);

			.content-block:nth-child(odd) & {
				background: darken($color-gray, 5%);
			}
		}
	}
}
﻿.visual {
	margin: 0;
	height: rem(500px);

	&__image {
		width: 100%;
		height: rem(500px);
		max-width: initial;
		object-fit: cover;
		object-position: center;
	}
}

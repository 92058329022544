﻿@font-face {
	font-family: 'Roboto Condensed';
	src: url('../../fonts/robotocondensed-light.woff2') format('woff2'), url('../../fonts/robotocondensed-light.woff') format('woff');
	font-weight: 300 ;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../../fonts/robotocondensed-regular.woff2') format('woff2'), url('../../fonts/robotocondensed-regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

@font-face {
	font-family: 'Roboto Condensed';
	src: url('../../fonts/robotocondensed-bold.woff2') format('woff2'), url('../../fonts/robotocondensed-bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-stretch: normal;
	unicode-range: U+0002-FFFD;
	font-display: block;
}

﻿.logo {
	z-index: 101;
	display: block;
	width: rem(320px);
	height: rem(62px);
	margin: rem(31px) 0 rem(25px);
	text-indent: -9999px;
	background: url(../../images/logo-ammerlaan.png) no-repeat center;
	background-image: url(../../images/logo-ammerlaan.svg), none;
}

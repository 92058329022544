﻿.social-media {
	@extend .clearfix;
	overflow: hidden;
	width: 100%;
	padding: 0;
	margin: 0 -8px;

	&.-center {
		display: flex;
		justify-content: center;
	}

	&__item {
		float: left;
		display: block;
		padding: 0 rem(8px);
	}

	&__link {
		position: relative;
		display: block;
		overflow: hidden;
		width: rem(36px);
		height: rem(36px);
		border-radius: 50%;
		text-indent: -9999px;
		transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
		background-color: $color-secondary;
		color: #fff;
		text-decoration: none;

		&:after {
			font-size: rem(16px);
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			transform: translate(-50%, -50%);
			text-indent: 0;
			transition: transform 0.2s ease-in-out;
			text-decoration: none;
		}

		&.-facebook:after {
			@include icon($icon-facebook);
			font-size: rem(17px);
		}

		&.-youtube:after {
			@include icon($icon-youtube);
			font-size: rem(23px);
		}

		&.-twitter:after {
			@include icon($icon-twitter);
		}

		&.-linkedin:after {
			//@include icon($icon-linkedin);
		}

		&.-google-plus:after {
			//@include icon($icon-google-plus);
		}

		&.-soundcloud:after {
			//@include icon($icon-soundcloud);
		}

		&.-vimeo:after {
			//@include icon($icon-vimeo);
		}

		&.-instagram:after {
			@include icon($icon-instagram);
		}

		&.-flickr:after {
			//@include icon($icon-flickr);
		}

		&.-pinterest:after {
			//@include icon($icon-pinterest);
		}

		&:hover {
			color: $color-primary;
			background: #fff;
		}
	}
}

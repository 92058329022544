﻿button,
input[type='submit'],
input[type='button'],
input[type='reset'] {
	font-size: rem(18px);
	line-height: rem(43px);
	font-weight: bold;
	color: $color-primary;
	overflow: hidden;
	min-height: 0; // Using line-height instead
	padding: 0 rem(20px);
	border-radius: $button-border-radius;
	vertical-align: middle;
	transition: all 0.2s ease-out;
	transform: perspective(1px) translateZ(0); // Improve performance on mobile/tablet devices // Perspective reduces blurryness of text in Chrome
	box-shadow: 0 0 1px rgba(0, 0, 0, 0); // Improve aliasing on mobile/tablet devices

	&:hover,
	&:focus,
	&:active {
		background: $color-primary;
		color: #fff;
	}

	&.-full-width {
		width: 100%;
	}

	&.-full {
		line-height: rem(45px);
		border: none;
	}

	&.-white {
		border-color: #fff;
		color: #fff;

		&:hover,
		&:focus,
		&:active {
			background: #fff;
			color: $color-primary;
		}
	}

	&.-secondary {
		border-color: $color-secondary;
		color: $color-secondary;

		&:hover,
		&:focus,
		&:active {
			background: $color-secondary;
			color: #fff;
		}

		&.-full {
			border-color: $color-secondary;
			background: $color-secondary;
			color: #fff;

			&:hover,
			&:focus,
			&:active {
				background: $color-primary;
				color: #fff;
			}
		}
	}
}

.button {
	@extend button;
}

.buttons {
	margin: rem(-5px) rem(-10px);

	.button {
		margin: rem(5px) rem(10px)
	}

	&:last-child {
		margin: 0 rem(-5px) rem(13px);
	}

	&.-no-bottom-margin {
		margin-bottom: 0;
	}
}
﻿.route-form {
	position: absolute;
	z-index: 10;
	width: 100%;
	top: rem(50px);

	.input-group {
		margin: 0;
		box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
	}
}

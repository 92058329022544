@mixin definition-list($width, $bp: xs) {
	@extend .clearfix;
	overflow: hidden;

	dt,
	dd {

		@include bp-min(#{$bp}) {
			float: left;
		}
	}

	dt {
		width: #{$width}px;
	}

	dd {
		margin: 0;

		@include bp-min(#{$bp}) {
			width: calc(100% - #{$width}px);
		}
	}
}

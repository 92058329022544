﻿.breadcrumbs {
	font-size: rem(16px);

	&__list {
		@extend .no-bullets;
		overflow: hidden;
		padding: 0;
		margin: rem(-2px) rem(-5px) rem(-5px);
	}

	&__item {
		float: left;
		margin: rem(5px) rem(5px) rem(5px) rem(5px);

		i {
			position: relative;
			font-size: rem(6px);
			margin: 0 0 0 rem(11px);
			bottom: rem(2px);
		}
	}

	&__link {
		position: relative;
		color: inherit;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}
	}
}

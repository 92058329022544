﻿// Colors
$color-primary: #685bc7;
//$color-primary-light: #94bdd0; //mix(#136e97, #fff);
$color-primary-dark: #53499f;
$color-primary-darker: #2d2855;
$color-gray: #efeef2;
$color-border: #b2b2b2;
$color-secondary: #981d97;
$color-gray-border: rgba(#5e5a7e, 0.3);

//$color-secondary-light: #e17585;
//$color-tertiary: #49c76f;
// General Styles
$font-path: "../../fonts/";
$base-font-size: 100%; // 18px
$base-font-size-px: 16px;
$base-line-height: 1.625; // 26px
$base-font-family: 'Roboto Condensed', Arial, Helvetica, sans-serif;
$base-text-color: #000;

// Typography
// These have been calculated to match the default browser sizes.
$h1-font-size: rem(40px);
$h1-line-height: rem(40px);
$h1-margin: 0;

$h2-font-size: $h1-font-size;
$h2-line-height: $h1-line-height; 
$h2-margin: rem(25px);

//$h3-font-size: 1.5rem; // 30px
//$h3-line-height: 1; // 30px
//$h3-margin: 1rem; // 20px

//$h4-font-size: 1rem !default;
//$h4-margin: 1.33rem !default;

//$h5-font-size: .83rem !default;
//$h5-margin: 1.386rem !default;

//$h6-font-size: .67rem !default;
//$h6-margin: 1.561rem !default;

// Selected text
$selection-color: #fff;
$selection-background: lighten($color-primary, 10%);

// Focused objects
$selection-focus-color: transparent;

//==
// Grid System
//##
$grid-container-max-width: 1368px;
//$grid-column-count: 12 !default;
$grid-column-gutter: 40px;
$grid-breakpoint-container-max: $grid-container-max-width + $grid-column-gutter;

// Breakpoints. These have been carefully considered so it
// is recommended to leave them as they are.
$grid-breakpoint-t-max: 26.99em;
$grid-breakpoint-xxs-min: 27em; // 432 px
$grid-breakpoint-xxs-max: 37.49em;
$grid-breakpoint-xs-min: 37.5em; // 600px
$grid-breakpoint-xs-max: 47.99em;
$grid-breakpoint-s-min: 48em; // 768px
$grid-breakpoint-s-max: 61.99em;
$grid-breakpoint-m-min: 62em; // 992px
$grid-breakpoint-m-max: 74.99em;
$grid-breakpoint-l-min: 75em; // 1200px
$grid-breakpoint-l-max: 119.99em;
$grid-breakpoint-xl-min: 120em; // 1920px
$grid-breakpoint-xl-max: 159.99em;
$grid-breakpoint-xxl-min: 160em; // 2560px

// Print Breakpoints. Printing on A4 only maintains
// xs grid layout so we shift the xs/s breakpoint to force it to print using the
// s grid. These are deliberately set at pixel values to ensure
// that font-size doesn't affect output.
//$grid-print-breakpoint-xs-max: 536px !default;
//$grid-print-breakpoint-s-min: 537px !default;

//==
// Basic Elements
//##

// Horizontal Rule
//$horizontal-rule-color: #c0c0c0 !default;

// Tables
//$table-cell-pad: .5rem !default;
//$table-bordered-border: 1px solid #c0c0c0 !default;
//$table-striped-background-color: #ebebeb !default;

//==
// CSS Components
//##

// Forms
//$form-line-height: 1.4 !default;
$form-placeholder: #000;
$form-input-padding: rem(10px) rem(20px);
$form-input-height: rem(45px); // 50px
$form-input-height-small: ($form-input-height - 0.5rem);
$form-input-height-large: ($form-input-height + 1.5rem);
//$form-input-background: #fff !default;
$form-input-border: 0.05rem solid rgba(#000, 0.1);
$form-input-border-radius: 0;
$form-input-color: #000;

//$form-input-background-disabled: #ebebeb !default;
//$form-input-border-color-disabled: #c0c0c0 !default;
//$form-input-color-disabled: #c0c0c0 !default;

//$form-input-background-readonly: null !default;
//$form-input-border-color-readonly: #ebebeb !default;
//$form-input-color-readonly: null !default;

// Buttons
$button-background: transparent;
$button-border: 1px solid $color-primary;
$button-border-radius: 0;
$button-color-disabled: inherit;

// Lists
$list-colored-bullet: $color-primary;

// Alerts
$alert-background-color: rgba($color-primary, 0.1);
$alert-border-color: $color-primary;

// Code
//$code-font-family: monospace, monospace !default;
//$code-background-color: #f7f7f9 !default;
//$code-border-color: #c0c0c0 !default;
//$code-color: #950b2f !default;

//==
// JS Plugins
//##

// Navigation
$navigation-width: 100%;
$navigation-background-color: #000;
//$navigation-color: #fff !default;

// Modal
$modal-overlay-background-color: #000;

//$modal-header-background: transparent !default;
//$modal-header-color: #fff !default;

//$modal-footer-background: transparent !default;
//$modal-footer-color: #fff !default;

$modal-content-background-color: none;
$modal-media-background-color: none;

//$modal-control-color: #fff !default;

// Carousel
//$carousel-caption-background-color: rgba(34, 34, 34,.4) !default;
//$carousel-caption-color: #fff !default;

//$carousel-control-color: #fff !default;

//$carousel-nav-background-color: transparent !default;
//$carousel-nav-border: 1px solid #222 !default;

//$carousel-nav-background-color-hover: #c0c0c0 !default;
//$carousel-nav-border-color-hover: null !default;

//$carousel-nav-background-color-active: #fff !default;
//$carousel-nav-border-color-active: null !default;

// Tabs
//$tabs-nav-border-color: #c0c0c0 !default;

//$tabs-nav-item-background-color: null !default;
//$tabs-nav-item-border-color: transparent !default;
//$tabs-nav-item-color: null !default;

//$tabs-nav-item-background-color-hover: #ebebeb !default;
//$tabs-nav-item-border-color-hover: #c0c0c0 !default;
//$tabs-nav-item-color-hover: null !default;

//$tabs-nav-item-background-color-active: #fff !default;
//$tabs-nav-item-border-color-active: #c0c0c0 !default;
//$tabs-nav-item-color-active: #454545 !default;

// Dropdown
//$accordion-border-color: #c0c0c0 !default;

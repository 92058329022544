﻿.call-to-action {
	position: relative;
	overflow: hidden;
	z-index: 0;
	margin: 0;
	background: $color-primary;

	&__image-container {
		position: relative;
		z-index: 0;
		height: rem(500px);
		width: 100%;
		margin: 0;
		opacity: 0.4;
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&__content-container {
		position: absolute;
		z-index: 1;
		width: 100%;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
		padding: 0;
		color: #fff;

		@include bp-min(s) {
			padding: 0 rem(40px);
		}
	}

	&__content {
		position: relative;
		padding: 0 0 0 rem(50px);

		&:before {
			position: absolute;
			content: '';
			width: 2px;
			height: 100%;
			top: 0;
			left: 0;
			background: #fff;
		}

		&:after {
			position: absolute;
			content: '';
			width: 2px;
			height: rem(500px);
			top: 50%;
			left: 0;
			background: #fff;
		}
	}

	&__title {
		margin: 0 0 rem(5px);
		font-weight: 300;
		color: #fff;
	}

	&__text {
		display: block;
		margin: 0 0 rem(35px);
		font-size: rem(30px);
		line-height: rem(35px);
		font-weight: bold;

		@include bp-min(xxs) {
			font-size: rem(40px);
			line-height: rem(45px);
		}

		@include bp-min(xs) {
			font-size: rem(50px);
			line-height: rem(55px);
		}

		@include bp-min(s) {
			font-size: rem(60px);
			line-height: rem(65px);
		}
	}
}

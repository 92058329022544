﻿.video {
	display: block;
	position: relative;
	width: 100%;
	height: rem(426px);
	margin: 0 0 rem(25px);

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&:before,
	&:after {
		position: absolute;
		content: '';
		width: rem(120px);
		height: rem(84px);
		margin: rem(-42px) 0 0 rem(-60px);
		top: 50%;
		left: 50%;
		transition: opacity 0.3s ease-in-out;
	}

	&:before {
		z-index: 1;
		background: url(../../images/play.png) center;
		background-image: url(../../images/play.svg), none;
		opacity: 1;
	}

	&:after {
		z-index: 2;
		background: url(../../images/play-hover.png) center;
		background-image: url(../../images/play-hover.svg), none;
		opacity: 0;
	}

	&:hover {
		
		&:after {
			opacity: 1;
		}
	}
}

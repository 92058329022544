﻿%side-line-primary {
	&:before {
		background: $color-primary;
	}
}

.side-line {
	position: relative;

	&:before {
		position: absolute;
		content: '';
		width: 2px;
		height: 100%;
		top: 0;
		left: 0;
		background: #fff;
	}

	&.-small:before {
		width: 1px;
	}

	&.-border:before {
		background: $color-border;
	}

	&.-primary {
		@extend %side-line-primary;
	}

	&.-white:before {
		background: #fff;
	}

	&__padding {
		padding-left: rem(20px);

		@include bp-min(xxs) {
			padding-left: rem(50px);
		}
	}
}

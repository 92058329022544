﻿.text {

	.-form & {
		color: #fff;
	}

	p,
	ul,
	ol {
		margin: 0 0 rem(27px);
	}

	ul,
	ol {
		@extend .no-bullets;

		li {
			position: relative;
			padding: rem(6px) 0 rem(9px) rem(18px);

			&:before {
				position: absolute;
				content: '';
				width: rem(4px);
				height: rem(4px);
				border-radius: 50%;
				top: rem(20px);
				left: 0;
				background: $color-primary;
			}
		}
	}

	+ .buttons {
		margin-top: rem(27px)
	}

	a {
		text-decoration: none;
		color: $color-primary;

		&:hover {
			text-decoration: underline;
		}

		.-form & {
			color: $color-primary-darker;
		}
	}

	&:last-child {

		p,
		ul,
		ol {

			&:last-child {
				margin: 0 0 rem(18px);
			}
		}
	}

	&.-last-no-margin {

		p,
		ul,
		ol,
		table {

			&:last-child {
				margin: 0;
			}
		}
	}
}

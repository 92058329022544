﻿.hero {
	position: relative;
	display: flex;
	align-items: center;
	z-index: 1;
	color: #fff;
	padding: 0;
	height: rem(610px);
	background: $color-primary;
	overflow: hidden;

	&__content-container {
		width: 100%;
	}

	&__content {
		padding: rem(2px) 0 0;
		@extend .side-line;
		@extend .side-line__padding;

		&:after {
			position: absolute;
			content: '';
			height: rem(610px);
			width: 2px;
			top: 0;
			left: 0;
			background: #fff;
		}
	}

	&__title {
		font-weight: 400;
	}

	&__sub-title {
		font-size: rem(40px);
		line-height: rem(40px);
		color: #fff;

		@include bp-min(xxs) {
			font-size: rem(65px);
			line-height: rem(65px);
		}
	}

	&__image-container {
		position: absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		margin: 0;
		top: 0;
		left: 0;
		opacity: 0.3;
	}

	&__image {
		position: absolute;
		width: 100%;
		height: 100%;
		margin: 0;
		top: 0;
		object-fit: cover;
		object-position: center;
	}

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: rem(10px);
		bottom: 0;
		left: 0;
		background-color: $color-secondary;
	}
}

﻿[data-navigation],
.navigation {
	width: 100%;

	@include bp-min(m) {
		width: auto;
	}

	ul {
		@extend .clearfix;
		@extend .no-bullets;
	}

	> ul {
		width: 100%;
		margin: 0;

		@include bp-min(m) {
			width: auto;
		}

		> li {
			position: relative;
			width: 100%;

			@include bp-min(m) {
				float: left;
				width: initial;
				width: auto;
			}

			> a {
				padding: rem(17px) rem(20px) rem(11px);
				font-weight: bold;
				border-top: 1px solid $color-primary;

				@include bp-min(m) {
					padding: rem(47px) rem(20px) rem(41px);
					border-top: none;
				}

				&:hover,
				&:focus,
				&[aria-expanded=true] {

					span {

						&:after {
							background: $color-primary;
							transform: scaleX(1);
						}
					}
				}
			}

			&:last-child > a {
				border-bottom: 1px solid $color-primary;
			}

			@include bp-min(m) {

				&:last-child > a {
					border-bottom: none;

					&:after {
						position: absolute;
						content: '';
						width: 1px;
						margin: rem(-5px) 0 0 0;
						height: rem(14px);
						top: rem(50%);
						right: -1px;
						background: $color-border;
					}
				}
			}
			// Second layer
			> div {

				@include bp-min(m) {
					position: absolute;
					left: rem(-30px);
				}

				> ul {
					padding: 0;
					background: $color-gray;

					@include bp-min(m) {
						//position: absolute;
						min-width: rem(356px);
						padding: rem(32px) 0 rem(34px) rem(31px);
					}

					> li {
						width: 100%;

						> a {
							color: $base-text-color;
							text-transform: uppercase;
							font-weight: bold;
							font-size: rem(16px);

							&:after {
								@include icon($icon-arrow-bold-right);
								position: relative;
								font-size: rem(10px);
								bottom: 1px;
								left: rem(5px);
								transition: left 0.2s ease-in-out;
							}

							&:hover {

								&:after {
									left: rem(8px);
								}
							}

							> span {
								position: relative;
							}
						}

						&:last-child a:before {
							top: auto;
							margin: 0 0 rem(7px);
							bottom: 0;
						}
					}

					.level-2 {
						padding: 0 0 rem(20px) 0;

						@include bp-min(m) {
							padding: 0;
						}
					}
					// Third layer
					a {
						position: relative;
						padding: rem(1px) rem(30px) rem(0) rem(18px);
						text-transform: none;
						white-space: nowrap;
						color: $color-primary;

						&:before {
							height: 100%;
						}

						&:hover {
							color: $color-primary-darker;
						}
					}
				}
			}
		}

		a {
			display: block;
			font-size: rem(18px);
			line-height: rem(30px);
			text-decoration: none;
			color: $base-text-color;

			&:before {
				position: absolute;
				content: '';
				width: 1px;
				margin: rem(-4px) 0 0 0;
				height: rem(14px);
				top: rem(50%);
				left: -1px;
				background: $color-border;
			}

			&:hover,
			&:focus,
			&[aria-expanded=true] {
				background: $color-gray;
				color: $color-primary;
			}
		}

		span {
			position: relative;
			padding: 0;

			&:after {
				position: absolute;
				content: '';
				width: 100%;
				height: rem(2px);
				bottom: rem(-8px);
				left: 0;
				background: $base-text-color;
				transform: scaleX(0);
			}

			.active & {

				&:after {
					transform: scaleX(1);
				}
			}
		}
	}
}

﻿form {

	input {
		margin: 0 0 rem(10px);
		font-size: rem(16px);

		@include placeholder {
			color: rgba($base-text-color, 0.7);
		}
	}

	.email2 {
		display: none;
	}

	textarea {
		min-height: rem(320px);
		margin: 0 0 rem(10px);
		font-size: rem(16px);

		.form-contactform & {
			min-height: rem(155px);
		}

		@include placeholder {
			color: rgba($base-text-color, 0.7);
		}
	}
}

﻿.image {

	&__container {
		margin: 0 0 rem(26px);

		&.-large {
			@include responsive-container-psudo(1278, 426);
			background: rgba($color-primary, 0.2)
		}

		&.-small {

			@include bp-min(m) {
				margin: 0;
			}
		}
	}
}
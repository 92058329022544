﻿.header {
	position: relative;
	color: #fff;
	background: $color-primary url(../../images/header-background.jpg) center no-repeat;
	background-size: cover;
	padding: rem(30px) 0 0;
	font-weight: bold;

	&:after {
		position: absolute;
		content: '';
		width: 100%;
		height: rem(10px);
		bottom: 0;
		left: 0;
		background: $color-secondary;
	}

	&__hgroup {
		position: relative;
		padding: 0 0 rem(32px);

		@extend .side-line;
		@extend .side-line__padding;
	}
}

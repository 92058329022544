﻿.responsive-nav {
	position: fixed;
	z-index: 1000;
	width: 100%;
	height: 100%;
	padding: rem(50px) 0 rem(80px);
	top: 0;
	right: 0;
	background: #fff;
	transition: all 0.3s ease-in-out;
	visibility: hidden;
	opacity: 0;

	@include bp-min(m) {
		float: right;
		position: static;
		width: auto;
		height: auto;
		visibility: visible;
		opacity: 1;
		padding: 0;
	}

	&.-open {
		visibility: visible;
		opacity: 1;
	}

	&__inner {
		height: 100%;

		@include bp-max(m) {
			overflow-x: hidden;
			overflow-y: visible;
		}

		@include bp-min(m) {
			overflow: initial;
			height: auto;
		}
	}
}
